import { Card, Typography, Stack, Button, Box, CssBaseline, ThemeProvider } from '@mui/material'
import { Container } from '@mui/system'
import TitleBar from '../TitleBar/TitleBar'
import theme from '../theme'

export default function Research() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <TitleBar />
                <Container maxWidth="lg">
                    <Typography
                        variant="h3">
                        Research
                    </Typography>
                    <Typography
                        variant="subtitle1">
                        My research is on intelligent music production, specifically focused on developing new user interactions from semantically driven data sets. However I am also involved in several different research avenues to achieve this goal.
                    </Typography>
                    <Typography variant="h3">
                        Links
                    </Typography>
                    <Stack direction="column" spacing={3}>
                    <Typography variant="body1"><a href="https://scholar.google.co.uk/citations?user=oNx9j4sAAAAJ">Google Scholar</a></Typography>
                    </Stack>
                    <Typography variant="h3">
                        Published Research
                    </Typography>
                    <Typography variant="h5">
                        2022
                    </Typography>
                    <Stack direction="column" spacing={3}>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">PhD Thesis -  Automating the Production of the Balance Mix in Music Production</Typography>
                            <Typography variant="body1">Nicholas Jillings</Typography>
                            <Typography variant="body1">A thesis submitted in partial fulfilment of the requirements for the degree of Doctor of Philosophy, May 2022</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://core.ac.uk/download/591925771.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                    </Stack>
                    <Typography variant="h5">
                        2018
                    </Typography>
                    <Stack direction="column" spacing={3}>
                    <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Investigation into the effects of subjective test interface choice on the validity of results</Typography>
                            <Typography variant="body1">Nicholas Jillings, Brecht de Man, Ryan Stables and Joshua D Reiss</Typography>
                            <Typography variant="body1">145th Convention of the Audio Engineering Society. New York, NY, USA. October 2018 </Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="http://eecs.qmul.ac.uk/~josh/documents/2018/19760.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Comparing stage metaphor interfaces as a controller for stereo position and level</Typography>
                            <Typography variant="body1">Brecht de Man, Nicholas Jillings and Ryan Stables</Typography>
                            <Typography variant="body1">4th Workshop on Intelligent Music Production, Huddersfield, UK, September 2018</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="http://www.brechtdeman.com/publications/pdf/WIMP4.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                    </Stack>
                    <Typography variant="h5">
                        2017
                    </Typography>
                    <Stack direction="column" spacing={3}>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">ADTWeb - An open source browser based automatic drum transcription system</Typography>
                            <Typography variant="body1">Carl Southall, Nicholas Jillings, Ryan Stables and Jason Hockman</Typography>
                            <Typography variant="body1">18th International Society for Music Information Retrieval Conference. Suzhou, China. October, 2017</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/6170/1/Southall2017b.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Automated Masking Reduction in Balance Mixes Using Genetic Programming</Typography>
                            <Typography variant="body1">Nicholas Jillings and Ryan Stables</Typography>
                            <Typography variant="body1">143rd Audio Engineering Society Convention. New York City, NY, USA. October, 2017</Typography>
                            <Button color="primary" variant="contained"
                                href="https://aes2.org/publications/elibrary-page/?id=19210">Get Bibtex</Button>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Zero-Delay Large Signal Convolution Using Multiple Processor Architectures</Typography>
                            <Typography variant="body1">Nicholas Jillings, Joshua D. Reiss and Ryan Stables</Typography>
                            <Typography variant="body1">2017 IEEE Workshop on Applications of Signal Processing to Audio and Acoustics (WASPAA). New Paltz, NY, USA. October, 2017</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/5705/1/zero%20delay%20large%20signal%20convolution%20using.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Automatic channel routing using musical instrument linked data</Typography>
                            <Typography variant="h5">Nicholas Jillings and Ryan Stables</Typography>
                            <Typography variant="body1">3rd Workshop on Intelligent Music Production. Manchester, UK. September, 2017</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/4969/1/WIMP2017_JillingsStables.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Audio Processing Chain Recommendation using Semantic Cues</Typography>
                            <Typography variant="body1">Spyridon Stasis, Nicholas Jillings, Sean Enderby and Ryan Stables</Typography>
                            <Typography variant="body1">3rd Workshop on Intelligent Music Production. Manchester, UK. September, 2017</Typography>
                            <Button color="primary" variant="contained"
                                href="https://www.open-access.bcu.ac.uk/4967/1/WIMP2017_StasisEtAl.pdf" >Get PDF</Button>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Audio Processing Chain Recommendation</Typography>
                            <Typography variant="body1">Spyridon Stasis, Nicholas Jillings, Sean Enderby and Ryan Stables</Typography>
                            <Typography variant="body1">20th International Conference on Digital Audio Effects. Edinburgh, UK. September, 2017</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="http://www.dafx17.eca.ed.ac.uk/papers/DAFx17_paper_75.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Intelligent audio plugin framework for the Web Audio API</Typography>
                            <Typography variant="body1">Nicholas Jillings, Yonghao Wang, Ryan Stables and Joshua Reiss</Typography>
                            <Typography variant="body1">3rd Web Audio Conference. London, UK. August, 2017</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/4964/1/38.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">An Intelligent audio workstation in the browser</Typography>
                            <Typography variant="body1">Nicholas Jillings and Ryan Stables</Typography>
                            <Typography variant="body1">3rd Web Audio Conference. London, UK. August, 2017</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/4965/1/37.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Investigating Music Production using a Semantically Powered Digital Audio Workstation in the Browser</Typography>
                            <Typography variant="body1">Nicholas Jillings and Ryan Stables</Typography>
                            <Typography variant="body1">Audio Engineering Society Semantic Audio Conference. Erlangen, Germany. June, 2017</Typography>
                            <Button color="primary" variant="contained"
                                href="http://dmtlab.bcu.ac.uk/nickjillings/papers/bibtex/jillings2017sac.bib" >Get Bibtex</Button>
                        </Card>
                    </Stack>
                    <Typography variant="h5">
                        2016
                    </Typography>
                    <Stack direction="column" spacing={3}>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">JSAP: A Plugin Standard for the Web Audio API with Intelligent Functionality</Typography>
                            <Typography variant="body1">Nicholas Jillings, Yonghao Wang, Joshua D Reiss and Ryan Stables</Typography>
                            <Typography variant="body1">Audio Engineering Society Convention 141. Los Angeles, CA, USA. September, 2016</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.academia.edu/download/51519982/Jillings_-_JSAP_-_AES141.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">JSAP: Intelligent Audio Plugin Standard for the Web Audio API</Typography>
                            <Typography variant="body1">Nicholas Jillings and Ryan Stables</Typography>
                            <Typography variant="body1">Proceedings of the 2nd AES Workshop on Intelligent Music Production. London, UK. September, 2016</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/4348/1/Jillings.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Subjective Comparison of Music Production Practices Using the Web Audio Evaluation Tool</Typography>
                            <Typography variant="body1">Brecht De Man, Nicholas Jillings, David Moffat, Joshua D Reiss and Ryan Stables</Typography>
                            <Typography variant="body1">Proceedings of the 2nd AES Workshop on Intelligent Music Production. London, UK. September, 2016</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/4110/1/subjective%20comparison.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">JS-Xtract: A Realtime audio feature extraction library for the web</Typography>
                            <Typography variant="body1">Nicholas Jillings, Jamie Bullock and Ryan Stables</Typography>
                            <Typography variant="body1">17th International Society for Music Information Retrieval Conference. New York, NY, USA. August, 2016</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/4099/1/JS%20Xtract.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Web Audio Evaluation Tool: A framework for subjective assessment of audio</Typography>
                            <Typography variant="body1">Nicholas Jillings, David Moffat, Brecht De Man, Joshua D Reiss and Ryan Stables</Typography>
                            <Typography variant="body1">2nd Web Audio Conference. Atlanta, GA, USA. April, 2016</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.open-access.bcu.ac.uk/3272/1/web%20audio.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                    </Stack>
                    <Typography variant="h5">
                        2015
                    </Typography>
                    <Stack direction="column" spacing={3}>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Web Audio Evaluation Tool: A browser-based listening test environment</Typography>
                            <Typography variant="body1">Nicholas Jillings, Brecht De Man, David Moffat and Joshua D Reiss</Typography>
                            <Typography variant="body1">12th Sound and Music Computing Conference. Maynooth, Ireland. July, 2015</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="http://www.eecs.qmul.ac.uk/~josh/documents/2015/Jillings%20-%20SMC10%202015.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Adaptive audio reproduction using personalized compression</Typography>
                            <Typography variant="body1">Andrew Mason, Nick Jillings, Zheng Ma, Joshua D Reiss and Frank Melchior</Typography>
                            <Typography variant="body1">57th Audio Engineering Society Conference: The Future of Audio Entertainment Technology–Cinema, Television and the Internet. Los Angeles, CA, USA. March, 2015</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.eecs.qmul.ac.uk/~josh/documents/2015/Mason%20et%20al%20-%20AES57%20-%202015.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                    </Stack>
                    <Typography variant="h5">
                        2014
                    </Typography>
                    <Stack direction="column" spacing={3}>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">CUDA Accelerated Audio Digital Signal Processing for Real-Time Algorithms</Typography>
                            <Typography variant="body1">Nicholas Jillings and Yonghao Wang</Typography>
                            <Typography variant="body1">Audio Engineering Society Convention 141. Los Angeles, CA, USA. October, 2014</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.researchgate.net/profile/Nicholas-Jillings/publication/292854753_CUDA_accelerated_audio_digital_signal_processing_for_real-time_algorithms/links/58c12816aca272094401076d/CUDA-accelerated-audio-digital-signal-processing-for-real-time-algorithms.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                    </Stack>
                    <Typography variant="h5">
                        2013
                    </Typography>
                    <Stack direction="column" spacing={3}>
                        <Card variant="outlined" style={{ padding: '10px' }}>
                            <Typography variant="h5">Performance optimization of GCC-PHAT for delay and polarity correction under real world conditions</Typography>
                            <Typography variant="body1">Nicholas Jillings, Alice Clifford and Joshua Reiss</Typography>
                            <Typography variant="body1">Audio Engineering Society Convention 134. Rome, Italy. May, 2013</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button color="primary" variant="contained"
                                    href="https://www.eecs.qmul.ac.uk/~josh/documents/Jillings%20Clifford%20Reiss-%20Performance%20Optimization%20of%20GCC-PHAT%20for%20Delay%20and%20Polarity%20Correction%20under%20Real%20World%20Conditions.pdf" >Get PDF</Button>
                            </Stack>
                        </Card>
                    </Stack>
                </Container>
            </Box>
        </ThemeProvider>
    )
}