import { Box, ButtonBase, Grid, styled, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const images = [
    {
        url: '/images/paint1.jpg',
        title: 'Art Story',
        href: "/art-story"
    },
    {
        url: '/images/paint2.jpg',
        title: 'Gallery',
        href: "/gallery"
    },
    {
        url: '/images/paint3.jpg',
        title: 'Exhibitions',
        href: "/exhibitions"
    },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 500,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 250,
    },
    [theme.breakpoints.down('md')]: {
        height: 250,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));


function Home() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid
                item
                direction="column"
                alignItems="center"
                justifyContent="center"
                sm={1}>
                <Typography variant="h6">"An absolutely spiffing place to learn about audio, coding and beer" - Someone, somewhere, probably</Typography>
            </Grid>
            <Grid
                item
                direction="column"
                alignItems="center"
                justifyContent="center"
                sm={1}>
                <img src="hello.gif" />
            </Grid>
        </Grid>
    );
}

export default Home;
