import './TitleBar.css';
import { Button, ButtonGroup, Grid, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

export default function TitleBar() {
    const [menuOpen, setMenuState] = useState(false);
    return (
        <Grid
            display="grid"
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Grid
                item
                xs={12}>
                <Typography
                    align="center"
                    variant="h2"
                    color="inherit"
                    style={{fontFamily: "IBM Plex Mono, Helvetica, Arial, sans-serif"}}
                    component="div">
                    Nick Jillings
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}>
                <ButtonGroup
                    variant="text"
                    aria-label="outlined primary button group"
                    size="large"
                    sx={{display: { xs: 'none', sm: 'block' } }}>
                    <Link to="/"><Button>Home</Button></Link>
                    <Link to="/research"><Button>Research</Button></Link>
                    <Link to="/bio"><Button>Bio</Button></Link>
                    <Link to="/bucket-list"><Button>The Bucket List</Button></Link>
                </ButtonGroup>
                <IconButton aria-label="delete"
                    sx={{display: { xs: 'block', sm: 'none' } }}
                    onClick={() => setMenuState(!menuOpen)}
                    >
                    <MenuIcon sx={{display: menuOpen ? "none" : "block"}}/>
                    <CloseIcon sx={{display: menuOpen ? "block" : "none"}}/>
                </IconButton>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{display: menuOpen ? "block" : "none"}}>
                <ButtonGroup
                    variant="text"
                    aria-label="outlined primary button group"
                    size="large"
                    orientation="vertical">
                    <Link to="/"><Button>Home</Button></Link>
                    <Link to="/research"><Button>Research</Button></Link>
                    <Link to="/bio"><Button>Bio</Button></Link>
                    <Link to="/bucket-list"><Button>The Bucket List</Button></Link>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
  }