import { Grid, Typography, Box, Paper, Divider, CssBaseline, ThemeProvider } from '@mui/material'
import { Container } from '@mui/system'
import TitleBar from '../TitleBar/TitleBar'
import theme from '../theme'

export default function Bio() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <TitleBar />
                <Container maxWidth="lg">
                    <Divider flexItem />
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Grid item sm={3} xs={12}>
                            <Typography variant="h4">Skills:</Typography>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <ul>
                                <li>
                                    <Typography variant="body1">5+ Years of Web Development using TypeScript and JavaScript. Most used languages:</Typography>
                                    <ul>
                                        <li>
                                            <Typography variant="body1">Typescript (Node.js, React, Angular, Webpack)</Typography>
                                            <Typography variant="body1">Python</Typography>
                                            <Typography variant="body1">C, C++</Typography>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Typography variant="body1">4+ Years as an Executive running a lean start-up</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">Database Management (Postgres)</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">Cloud management (AWS, Azure)</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">Managing AWS deployments and infrastructure requirements</Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Divider flexItem />
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Grid item sm={3} xs={12}>
                            <Typography variant="h4">Experience:</Typography>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <Typography variant="h6">Semantic Audio Labs</Typography>
                            <Typography variant="subtitle1">Founder</Typography>
                            <Typography variant="subtitle2">2018 - Present</Typography>
                            <Typography variant="body1">A funded spin-out from Birmingham City University. The company built a web-based digital audio workstation with Machine Learning built-in, enabling artists and engineers to create their music and podcasts with ease, in the cloud, on any device.</Typography>
                            <Divider flexItem />
                            <Typography variant="h6">Birmingham City University</Typography>
                            <Typography variant="subtitle1">Visiting Lecturer</Typography>
                            <Typography variant="subtitle2">Feb 2016 - May 2022</Typography>
                            <Typography variant="body1">During the PhD I delivered several modules to undergraduates on Computer Science in Music. This included introductory modules on C++, Arduino Internet-of-Things, Machine Learning and Audio Effects.</Typography>
                            <Divider flexItem />
                            <Typography variant="h6">BBC R&D Audio Group</Typography>
                            <Typography variant="subtitle1">Research Assistant</Typography>
                            <Typography variant="subtitle2">April 2014 - September 2014</Typography>
                            <Typography variant="body1">After the initial role at Queen Mary, I was then sent to BBC R&D as part of a research programme with other PhD candidates from BBC R&D. I was shown the novel Web Audio API for music and audio manipulation in the browser. We were tasked with building a working demo to solve Dynamic Range Compression in an early BBC Sounds prototype.</Typography>
                            <Divider flexItem />
                            <Typography variant="h6">Queen Mary, University Of London</Typography>
                            <Typography variant="subtitle1">Research Assistant</Typography>
                            <Typography variant="subtitle2">September 2013 - April 2014</Typography>
                            <Typography variant="body1">As part of the undergraduate, I took a year out to work at Queen Mary. My role  was to convert academic output research papers, based on automatic music mixing tasks,  into working C++ programs. These ultimately were sold into a Patent on which I am cited.</Typography>
                        </Grid>
                    </Grid>
                    <Divider flexItem />
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Grid item sm={3} xs={12}>
                            <Typography variant="h4">Education:</Typography>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <Typography variant="h6">BSc Sound Engineering and Production</Typography>
                            <Typography variant="subtitle1">Birmingham City University</Typography>
                            <Typography variant="body1">Following from School I knew I wanted to go into audio. The degree exposed all aspects of music production from session management, time management, and introduced programming with audio as a learning vector. </Typography>
                            <Typography variant="body1">My Undergraduate thesis was on developing plugins using CUDA GPUs for audio processing and was published in the Audio Engineering Society Conferences.</Typography>
                            <Divider flexItem />
                            <Typography variant="h6">MSc Digital Signal Processing</Typography>
                            <Typography variant="subtitle1">Queen Mary, University of London</Typography>
                            <Typography variant="body1">Following from the undergraduate course, where I had spent a year at Queen Mary’s College, I went back to complete an MSc in Digital Signal Processing. This exposed me to a far higher knowledge of real-time systems, not just audio but image and communications based. My MSc Thesis involved comparing CUDA powered cards with early Intel GPUs for low-latency audio processing performance. The tool was written in C++, OpenCL and CUDA. This was then presented at an IEEE conference.</Typography>
                            <Divider flexItem />
                            <Typography variant="h6">PhD Automating the Mix Engineer</Typography>
                            <Typography variant="subtitle1">Birmingham City University</Typography>
                            <Typography variant="body1">With the work from the BBC giving a unique insight into the world of web audio, an idea of using an online data-collection tool like a DAW to understand how musicians and engineers approached the complex task of music production formed.</Typography>
                            <Typography variant="body1">An offer was extended by Birmingham City University to work in the Digital Media Technology Lab under Dr. Ryan Stables, with funding to look into the research topic. What followed was over 14 publications, a research grant and eventually the spin out Semantic Audio Labs.</Typography>
                            <Typography variant="body1">The two primary outputs of the research centered around automatic instrument grouping using graph theory to cluster tracks based on meta-data, such as instrument type or placements, and an automatic mixer using evolutionary computing to minimise inter-channel masking.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    )
}