import { orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        body1: {
            marginTop: 10,
        },
        h1: {
            fontFamily: [
                'IBM Plex Mono',
                'cursive',
            ].join(',')
        }
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: '10px 0px 10px 0px'
                } 
            }
        }
    }
});

export default theme;