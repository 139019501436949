import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, ThemeProvider } from '@mui/material';
// @ts-ignore
import TitleBar from './TitleBar/TitleBar.tsx';
// @ts-ignore
import Home from './Home/Home.tsx'
import theme from './theme';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <TitleBar />
                <Home />
            </Box>
        </ThemeProvider>
    );
}

export default App;
